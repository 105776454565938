// extracted by mini-css-extract-plugin
export var alt = "ReservationConfirmationCard-module--alt--438ef";
export var container = "ReservationConfirmationCard-module--container--10380";
export var desktopOnly = "ReservationConfirmationCard-module--desktopOnly--a3a01";
export var freeStay = "ReservationConfirmationCard-module--freeStay--375a0";
export var hotelName = "ReservationConfirmationCard-module--hotelName--52238";
export var labels = "ReservationConfirmationCard-module--labels--e70bf";
export var main = "ReservationConfirmationCard-module--main--51d1e";
export var mobileOnly = "ReservationConfirmationCard-module--mobileOnly--a95bb";
export var styledPopover = "ReservationConfirmationCard-module--styledPopover--29a90";
export var textLeft = "ReservationConfirmationCard-module--textLeft--4d3c3";
export var visuallyHidden = "ReservationConfirmationCard-module--visuallyHidden--0a2ff";