import * as React from "react";
import { OverlayTrigger } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { Popover } from "react-bootstrap";
import {
  container,
  hotelName,
  styledPopover,
  freeStay,
} from "./ReservationConfirmationCard.module.scss";

export const Container: React.FC<any> = (props) => {
  return (
    <div className={`${container} reservationConfirmationCard px-0`}>
      {props.children}
    </div>
  );
};

export const HotelName: React.FC<any> = (props) => {
  return <h4 className={`${hotelName} mb-2`}>{props.children}</h4>;
};

export const FreeStay: React.FC = () => {
  return <span className={freeStay}>FREE STAY</span>;
};

export const UpgradeOverlay: React.FC<any> = (props) => {
  const popover = (
    <StyledPopover pid="upgradePopup" {...props}>
      <div style={{ fontWeight: 700, paddingBottom: "10px" }}>
        {" "}
        Upgrade Details{" "}
      </div>
      <div>
        Upgrade offers are subject to availability and are not confirmed until
        day of check-in. You will not be charged until the upgrade is confirmed.
      </div>
    </StyledPopover>
  );
  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom"
      rootClose={true}
      overlay={popover}
    >
      <FontAwesomeIcon icon={faInfoCircle} className="ms-2" size="sm" />
    </OverlayTrigger>
  );
};

export const StyledPopover: React.FC<any> = React.forwardRef((props, ref) => {
  return (
    <div className={styledPopover}>
      <Popover id={props.pid} ref={ref} {...props}>
        {props.children}
      </Popover>
    </div>
  );
});
StyledPopover.displayName = "StyledPopover";
