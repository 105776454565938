// extracted by mini-css-extract-plugin
export var alt = "ReservationConfirmation-module--alt--82967";
export var container = "ReservationConfirmation-module--container--b79aa";
export var desktopOnly = "ReservationConfirmation-module--desktopOnly--3e1d6";
export var main = "ReservationConfirmation-module--main--b6917";
export var mobileOnly = "ReservationConfirmation-module--mobileOnly--0741a";
export var reservationCard = "ReservationConfirmation-module--reservationCard--4b352";
export var styledAccordian = "ReservationConfirmation-module--styledAccordian--9a41c";
export var textLeft = "ReservationConfirmation-module--textLeft--57f52";
export var visuallyHidden = "ReservationConfirmation-module--visuallyHidden--6a24a";
export var wrapper = "ReservationConfirmation-module--wrapper--ad6c2";