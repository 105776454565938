import React, { useState } from "react";
import { Accordion, Button, Card, Container, Tab } from "react-bootstrap";
import ReservationConfirmationCard from "../../components/common/ReservationConfirmationCard/ReservationConfirmationCard";
import { Row } from "../../components/global/Row/Row";
import {
  container,
  reservationCard,
  styledAccordian,
  wrapper,
} from "./ReservationConfirmation.module.scss";

export const StyledContainer: React.FC<any> = (props) => {
  return (
    <Container className={`${container}`} fluid="sm">
      {props.children}
    </Container>
  );
};
export const Wrapper: React.FC<any> = (props) => {
  return <div className={wrapper}>{props.children}</div>;
};
export const StyledAccordion: React.FC<any> = (props) => {
  return (
    <Accordion
      className={`${styledAccordian} ${props.className}`}
      defaultActiveKey={props.defaultActiveKey}
    >
      {props.children}
    </Accordion>
  );
};

export const AllReservations: React.FC<any> = (props) => {
  const [eventKey, setEventKey] = useState("conf-0");

  const handleTabSelection = (eventKey: string) => {
    setEventKey(eventKey);
  };

  return (
    <>
      <Tab.Container activeKey={eventKey}>
        {props.reservations.length > 1 && (
          <Row className="mt-4 displayFlex">
            {props.reservations.map((reservation: any, idx: number) => {
              return (
                <Button
                  key={`arc-${idx}`}
                  variant="link"
                  className={`roomNavButton ${
                    eventKey === `conf-${idx}` ? "active" : ""
                  }`}
                  onClick={() => handleTabSelection(`conf-${idx}`)}
                >
                  Room {idx + 1}
                </Button>
              );
            })}
          </Row>
        )}
        <Tab.Content>
          {props.reservations.map((reservation: any, idx: number) => {
            return (
              <Tab.Pane key={`conf-${idx}`} eventKey={`conf-${idx}`}>
                <AccordionResCard
                  reservation={reservation}
                  idx={idx}
                  collapsedCards={props.collapsedCards}
                  setReservationCancelDialogConfig={
                    props.setReservationCancelDialogConfig
                  }
                  handleHotelDataDisplay={props.handleHotelDataDisplay}
                  handleReservationDetailLoad={
                    props.handleReservationDetailLoad
                  }
                  totalReservations={props.totalReservations}
                />
              </Tab.Pane>
            );
          })}
        </Tab.Content>
      </Tab.Container>
    </>
  );
};

export const AccordionResCard: React.FC<{
  reservation: any;
  idx: number;
  collapsedCards: Array<any>;
  totalReservations?: number;
  setReservationCancelDialogConfig(): any;
  handleHotelDataDisplay(): any;
  handleReservationDetailLoad(): any;
}> = (props) => {
  return (
    <Container className={reservationCard} key={`conf-${props.idx}`}>
      <Card.Body id={`card-${props.reservation.crs_reservation_id}`}>
        <ReservationConfirmationCard
          key={`res-${props.reservation.crs_reservation_id}-${props.idx}`}
          reservation={props.reservation}
          type={props.reservation.type || "upcoming"}
          onCancel={props.setReservationCancelDialogConfig}
          page="reservation"
          onHotelDisplay={props.handleHotelDataDisplay}
          onReservationDetailLoad={props.handleReservationDetailLoad}
          lastName={props.reservation.lastName}
        />
      </Card.Body>
    </Container>
  );
};
